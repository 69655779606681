import Splide from "@splidejs/splide";

// partners slider
if (document.querySelector('.partners-slider')) {
    intializePartnerSlider();
}

function intializePartnerSlider() {
    var sliderselector = document.getElementsByClassName('partners-slider');

    for (var i = 0; i < sliderselector.length; i++) {
        const slide_ele = sliderselector[i];
        const partnersSlider = new Splide(slide_ele, {
            type: 'loop',
            arrows: false,
            perPage: 6,
            perMove: 1,
            gap: 30,
            pagination: false,
            autoplay: true,
            interval: 7000,
            breakpoints: {
                1200: {
                    perPage: 5,
                    gap: 25,
                },
                991: {
                    perPage: 4,
                    gap: 15,
                },
                640: {
                    perPage: 3
                },
            },
        });
        partnersSlider.mount();
    }
}