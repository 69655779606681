document.addEventListener("DOMContentLoaded", function () {

  // Handler for navigation toggle
  function toggleHeaderNavOpen() {
    var nav = document.getElementById("header__nav");
    nav.classList.toggle("header__nav--open");
    document.querySelector("body").classList.toggle("page--nav-open");
  }

  var headerToggle = document.getElementById("header__toggle");
  if (headerToggle) {
    headerToggle.addEventListener("click", toggleHeaderNavOpen);
  }

  var headerNavClose = document.getElementById("header__nav-close");
  if (headerNavClose) {
    headerNavClose.addEventListener("click", toggleHeaderNavOpen);
  }

  // Handler for navigation resize
  window.addEventListener("resize", function (event) {
    var nav = document.getElementById("header__nav");
    nav.classList.remove("header__nav--open");
    document.querySelector("body").classList.remove("page--nav-open");
  });

  // Handler for navigation links
  var linkHeaderNavItems = document.querySelectorAll("#header__nav-wrapper > .menu-helper li a:not(.menu__back)");
  linkHeaderNavItems.forEach(function (item) {
    var parent = item.parentElement;

    if (parent.classList.contains("menu__item--child")) {
      var arrowSpan = document.createElement("span");
      arrowSpan.className = "menu-item-arrow";
      parent.querySelector('a').appendChild(arrowSpan);

      arrowSpan.addEventListener("click", function (event) {
        event.preventDefault();
        event.stopPropagation();
        parent.classList.toggle("menu__item--open");
        var submenu = parent.querySelector(".menu__sub");
        if (submenu) {
          if (submenu.style.maxHeight) {
            submenu.style.maxHeight = null;
          } else {
            submenu.style.maxHeight = submenu.scrollHeight + "px";
          }
        }
      });
    }
  });

});
